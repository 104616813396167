import React from "react"

import Layout from "../../components/en/Layout"
import SEO from "../../components/en/SEO"
import Breadcrumbs from "../../components/en/Breadcrumbs"

import "./Pages.css"

const QuemSomos = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="About Us"
        canonical={`${process.env.GATSBY_HOST_URL}/en/about-us`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/pt/quem-somos`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage="About Us"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>About Us</h1>
          </div>

          <div className="content-static-pages">
            <p>
              Passionate about the rich Islamic universe and unhappy with how
              little people know about it, we decided to gather in one place
              several subjects that can enrich the discussion of “what is Islam”
              and “who are Muslims”, having culture and knowledge as the main
              pillars. Of course, all content has its sources checked, humanly
              evaluated by our team.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default QuemSomos
